header {
	.logo {
		@include bp(max-width, $large) {
			text-align: center;
		}

		a {
			display: inline-block;
		}

		.bg {
			height: 177px;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			width: 300px;

			@include bp(max-width, $large) {
				width: 250px;
				height: 120px;
			}
		}
	}

	.contact_info {
		display: inline-block;
		width: 100%;
		text-align: right;
		padding: 30px 0;

		@include bp(max-width, $large) {
			padding-top:0;
			text-align: center;
			padding-bottom: 10px;
		}

		a {
			display: inline-block;
			font-family: Rubik;
			font-size: 18px;
			font-weight: bold;
			color: $black;
		}

		.seperator {
			display: inline-block;
			font-size: 18px;
			font-weight: bold;
			color: $red;
		}
	}

	.desktop_menu {
		text-align: right;
	    padding-bottom: 40px;

	    @include bp(max-width, $large) {
			text-align: center;
			padding-bottom: 20px;
		}

		@include bp(max-width, $medium) {
			display: none;
		}

		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			display: inline-block;
			li {
				display: inline-block;
				margin: 0 10px;

				&:last-child {
					margin-right: 0;
				}

				a {
					display: inline-block;
					padding: 15px 0;
					width: 164px;
					border-radius: 25px;
					background: $black;

					font-size: 16px;
					font-weight: bold;
					text-align: center;
					color: white;
					text-decoration: none !important;

					@include bp(max-width, $xlarge) {
					    width: 134px;
					}

					&:hover {
						background: $red;
					}
				}

				.current {
					background: $red;
				}
			}
		}
	}
}

.mobile_menu {
	display: none;
}

.slicknav_menu {
	display:none;

	@include bp(max-width, $medium) {
		display: block;
	}
}