.footer {
	border-bottom: 11px solid #d0021b;
	position:relative;

	.footer_top {
		padding: 80px 0;
		z-index: 3;
		position: relative;

		.item {
			@include bp(max-width, $medium) {
				padding-bottom: 20px;
			}
			&:last-of-type {
				.line {
					display: none;
				}
			}
			.text {
				display: inline-block;
				width: 97%;
			    vertical-align: top;

			    @include bp(max-width, $xlarge) {
			    	width: 100%;
			    }

			    @include bp(max-width, $medium) {
					padding: 20px 0;
				}

				.title {
					text-align: center;
					display: inline-block;
					width: 100%;
					margin-bottom: 20px;
					span {
						font-size: 24px;
						font-weight: bold;
						color: white;
					}
				}

				.desc {
					text-align: center;
					span,p,a {
						font-size: 16px;
						color: white;
					}
				}
			}

			.line {
				height: 155px;	
				width: 1px;	
				border: 1px solid #616161;
				display: inline-block;

				@include bp(max-width, $xlarge) {
				    width: 100%;
    				height: 1px;
    			}

    			@include bp(max-width, $medium) {
    				width: 50%;
    				display: block;
    				margin: 0 auto;
    			}
			}
		}
	}

	.footer_bottom {
		text-align: center;
		display: inline-block;
		width: 100%;
		padding-bottom: 30px;

		z-index: 3;
		position: relative;
		span {
			font-size: 16px;
			font-weight: 300;
			font-family: $sfont;
			color: white;
		}

		a {
			color: white;
		}
	}

	.bg {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;

		position: absolute;
		top: 0;
		left: 0;

		height: 100%;
		width: 100%;
		z-index: 0;
	}

	.bg-overlay {
		position: absolute;
		top: 0;
		left: 0;

		height: 100%;
		width: 100%;
		z-index: 1;
		background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, #000000 100%);
	}
}