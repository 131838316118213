#map {
	height: 400px;
}

.contact_wrapper_wrapper {
	background: #f0f0f0;
	padding: 80px 0;
	.contact_wrapper {
		width: 80%;
	    display: block;
	    margin: 0 auto;

	    @include bp(max-width, $large) {
	    	width: 100%;
	    }

	    .g-recaptcha {
			float: right;
			display: block;
			margin-bottom: 15px;

			@include bp(max-width, $xsmall) {
				transform: scale(0.8);
			}
	    }

	    .contact {
	    	span {
				font-size: 20px;
				font-weight: bold;
				color: $black;
	    	}

	    	.phone {

	    	}

	    	.email {
	    		color: #d0021b;
	    		word-break: break-word;
    			display: block;
	    	}
	    }

	    .step {
	    	span {
	    		color: $black;
	    		font-size: 24px;
  				font-weight: bold;
	    	}
	    }

	    .consultation_desc {

	    	@include bp(max-width, $medium) {
				display: inline-block;
				width: 100%;
				margin: 20px 0;
	    	}

	    	p {
	    		font-family: $sfont;
				font-size: 16px;
				color: $black;
			    line-height: 30px;
	    	}
	    }

	    .file {
	    	position:relative;

	    	.fancy {
	    		position:absolute;

				width: 100%;
				top: 0;
				left: 0;
				background: white;
				border-radius: 4px;
				border: solid 1px #dfe3e9;
				font-size: 16px;
				color: #c0c0c0;
				font-weight: normal;
				padding: 5px 10px;

				.text {
					display: inline-block;
				}

				.info {
					display: inline-block;
				    float: right;
				}

	    	}
    	    
    	    input {
    	    	opacity: 0;
    	    }
	    }

	    .address {
	    	margin-top: 20px;
	    	p {
	    		font-size: 20px;
	    		color: $black;
	    		font-family: $sfont;
	    		margin-bottom: 0;
	    	}
	    }

	    .company {
    	    margin-top: 88px;

    	    @include bp(max-width, $medium) {
    	    	margin-top: 30px;
    	    }
	    	span {
	    		display: inline-block;
	    		width: 100%;
	    		font-size: 14px;
				color: #4a4a4a;
	    	}

	    	b {
	    		font-weight: 600;
	    	}
	    }

	    form {
			display: inline-block;
			width: 100%;

			@include bp(max-width, $medium) {
				margin-top: 20px;
			}

	    	label {
	    		font-size: 16px;
	    		color: $black;
	    		font-family: $sfont;
	    		font-weight: normal;
	    	}

	    	input {
	    		height: 36px;
	    	}

	    	input, textarea {
	    		font-size: 16px;
	    		font-family: $sfont;
	    		border-radius: 4px;
			    border: solid 1px #dfe3e9;
			    padding: 10px 20px;
			    display: inline-block;
			    width: 100%;

	    		&::-webkit-input-placeholder{/*Chrome/Opera/Safari*/ 
					color: #c0c0c0;
				} 

				 

				&::-moz-placeholder{/*Firefox19+*/ 
					color: #c0c0c0;
				} 

				 

				&:-ms-input-placeholder{/*IE10+*/ 
					color: #c0c0c0;
				} 

				 

				&:-moz-placeholder{/*Firefox18-*/ 
					color: #c0c0c0;
				} 
	    	}

	    	input[type="submit"] {
	    		height: 50px;
				border-radius: 25px;
				background-color: #689e2d;

				padding:10px 20px;

				color: white;
				font-size: 16px;
				font-weight: bold;
				text-align: center;

			    float: right;
		        width: unset;

			    @include bp(max-width, $medium) {
			    	width: 100%;
			    }

			    &:hover {
			    	background-color: $red;
			    	text-decoration: none;
			    }
	    	}
	    }
	}
}