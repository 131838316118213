//breakpoints

$xsmall: 479px;
$small: 599px;
$medium: 767px;
$large: 991px; //was 979px
$xlarge: 1199px;
$xxlarge: 1700px;


//colours

$red: #d0021b;
$black: #000000;


//fonts
$font: "Rubik", "Candara", "Segoe", "Segoe UI", "Optima", "Arial", "sans-serif";
$sfont: "Hind", "Candara", "Segoe", "Segoe UI", "Optima", "Arial", "sans-serif";