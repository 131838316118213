.content_slider {
	padding: 80px 0;
	.single_slider {
		.item {
			p {
				color: $black;
				font-size: 16px;
				font-family: $sfont;
				margin-bottom: 30px;
				line-height: 25px;
			}

			.bg {
				height: 460px;
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
			}
		}
	}

	.slick-dots {
		bottom: -30px;
		width: 66%;

		@include bp(max-width, $large) {
			bottom: -30px;
			width: 100%;
		}

		@include bp(max-width, $xsmall) {
			bottom: -45px;
		}

		li {
			button {
				&:before {
					background: $red;
				}
			}
		}
	}
}