

.content_header {
	// height: 258px;
  	background-image: linear-gradient(100deg, #b80017, #3d0007);
  	// padding: 30px 0;

  	@include bp(max-width, $medium) {
  		padding: 40px 0;
  	}

  	.col-sm-4, .col-sm-8 {
  		position: relative;
  		height: 258px;
		// float: none;
		display: inline-block;
		vertical-align: middle;

		@include bp(max-width, $medium) {
			width: 100%;
			text-align: center;
			height: inherit !important;
		}
  	}

  	.col-sm-4 {
  		@include bp(max-width, $medium) {
		    height: 100px;
  		}

  		@include bp(max-width, $xsmall) {
  			height: 170px;
  		}
  	}

  	.col-sm-8 {
  		@include bp(max-width, $medium) {
		    height: 180px;
  		}

		@include bp(max-width, $xsmall) {
  		    height: 230px;
  		}
  	}

  	.title {
  		position: relative;
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		height: 100%;

		@include bp(max-width, $medium) {
			position: inherit;
			height: inherit;
			padding: 10px 0;
		}

  		span {
			font-size: 36px;
			font-weight: 900;
			color: white;

			// padding: 20px 0;
			display: inline-block;
			text-align: right;

			position: relative; // or absolute
			transform: translateY(-50%); 
			-webkit-transform: translateY(-50%); 
			top: 50%; 
			width: 100%;

			@include bp(max-width, $large) {
				font-size: 28px;
			}

			@include bp(max-width, $medium) {
				text-align: center;

				position: inherit;
				top: inherit;
				-webkit-transform: inherit;
			    transform: inherit;

				br {
					display: none;
				}
			}
  		}
  	}

  	.description {
  		text-align: left;
  		position: relative; // or absolute
		transform: translateY(-50%); 
		-webkit-transform: translateY(-50%); 
		top: 50%; 
		width: 100%;

		@include bp(max-width, $medium) {
  			text-align: center;
  			position: inherit;
			height: inherit;
			padding: 10px 0;
			position: inherit;
			top: inherit;
			-webkit-transform: inherit;
		    transform: inherit;
  		}

  		.inner {
		    // padding: 40px 0;
			display: inline-block;
			text-align: right;

			span {
				display: inline-block;
				width: 100%;
			}

			.sub-heading {
				font-size: 22px;
  				font-weight: 300;
  				color: white;
  				text-align: left;
				display: inline-block;
				width: 100%;
				margin-bottom: 20px;

				@include bp(max-width, $large) {
					font-size: 18px;
				}
			}

			.content {
				font-family: $sfont;
				font-size: 16px;
				font-weight: 300;
				color: white;
				text-align: left;

				@include bp(max-width, $large) {
					font-size: 14px;
				}
			}

			span {

				@include bp(max-width, $medium) {
		  			text-align: center !important;
		  			position: inherit;
					top: inherit;
					-webkit-transform: inherit;
				    transform: inherit;
		  		}
			}

			.content_empty {
			    margin: 0;
			}
  		}
  	}
}

.large_title {
    margin-bottom: 10px;

    @include bp(max-width, $medium) {
    	text-align: center;
    }

	span {
		font-size: 48px;
		font-weight: bold;
		color: $black;

		@include bp(max-width, 992px) {
			font-size: 28px !important;
		}
	}
}

.latest_projects {
	background-color: #f0f0f0;
	padding: 80px 0 60px;

	@include bp(max-width, 992px) {
		padding: 40px 0 40px;
	}

	.projects {
		@include bp(max-width, $xlarge) {
	    	text-align: center;
	    }

		.item {
			display: inline-block;
			width: 25%;
			float: left;
		    padding: 20px 15px;
		    position: relative;

		    @include bp(max-width, $xlarge) {
		    	width: 33.333333%;
		    	float: none;
		    }

		    @include bp(max-width, $large) {
		    	width: 45%;
		    }

		    @include bp(max-width, $medium) {
		    	width: 80%;
		    }

		    &:hover {
		    	.inner {
		    		.bg {
		    			-webkit-filter: blur(6.2px);
  						filter: blur(6.2px);
		    		}
		    		.overlay {
		    			visibility: visible;
		    			opacity: 0.5;
  						background-color: #d0021b;
		    		}

		    		.arrow {
		    			visibility: visible;
		    		}
		    	}
		    }

		    .inner {
		    	position: relative;
	    	    overflow: hidden;
		    }

			.bg {
			    height: 250px;
			    background-position: center;
			    background-size: cover;
			    background-repeat: no-repeat;

			    @include transition(all .3s linear);
			}

			.overlay {
				height: 250px;
				text-align: center;
				width: 100%;
				visibility: hidden;
				position: absolute;
				top: 0;
				left: 0;

				@include transition(all .3s linear);

				
			}

			.arrow {
				visibility: hidden;
				position: absolute;
				transform: translateY(-50%);
				-webkit-transform: translateY(-50%);
				top: 50%;

				left: 0;
				right: 0;
				margin: 0 auto;
			}
		}
	}
}


.get_in_touch {
	padding: 80px 0 60px;

	.speech-icon {
		@include bp(max-width, 992px) {
			display: none;
		}
	}

	.inner {
		padding: 20px;
		border-radius: 4px;
		background: #e6e6e6;

		@include bp(max-width, $medium) {
			margin-bottom: 15px;
		}
	}

	.image {
		background: none;
		padding: 0 !important;

		.bg {
			height: 230px;
		    background-position: center;
		    background-size: contain;
		    background-repeat: no-repeat;

		    @include bp(max-width, $medium) {
		    	width: 300px;
    			margin: 0 auto;
		    }

		    @include bp(max-width, $xsmall) {
		    	width: 70%;
		    }
		}
	}

	.large_title {
		margin-bottom: 25px;
		display: inline-block;
		width: 100%;

		@include bp(max-width, 992px) {
			font-size: 28px !important;
		}
	}

	.title {
		span {
			font-size: 24px;
			font-weight: bold;
			color: $black;
		}
	}

	.desc {
		min-height: 75px;
		margin: 15px 0;
		span {
			font-size: 18px;
			color: $black;
			font-family: $sfont;
		}
	}

	.button {
		display: inline-block;
		width: 100%;
		a {
			font-size: 20px;
			font-weight: bold;
			color: white;
		    padding: 7px 20px;

			border-radius: 22px;
			background-color: #689e2d;
			border: solid 1px #d2d2d2;

			display: inline-block;
		    float: right;

		    @include bp(max-width, $small) {
		    	width: 100%;
		    }

		    &:hover {
		    	background-color: $red;
		    	text-decoration: none;
		    }
		}
	}
}

