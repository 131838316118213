.all_projects {
	padding: 80px 0 60px;
	background: #f0f0f0;

	.projects {

		.item {
		    padding: 20px 0;
			&:hover {
		    	.inner {
		    		.image {
		    			.inner_image {
		    				.bg {
				    			-webkit-filter: blur(6.2px);
		  						filter: blur(6.2px);
				    		}
				    		.overlay {
				    			visibility: visible;
				    			opacity: 0.5;
		  						background-color: #d0021b;
				    		}

				    		.text {
				    			visibility: visible;
				    		}
		    			}
		    		}
		    	}
		    }

			.inner {
			display: inline-block;
			width: 100%;
				.image {

					.inner_image {
						position: relative;
						overflow: hidden;
						.bg {
							height: 197px;
							background-position: center;
							background-size: cover;
							background-repeat: no-repeat;
						}

						.overlay {
							height: 100%;
							text-align: center;
							width: 100%;
							visibility: hidden;
							position: absolute;
							top: 0;
							left: 0;

							@include transition(all .3s linear);

							
						}

						.text {
							visibility: hidden;
							position: absolute;
							transform: translateY(-50%);
							-webkit-transform: translateY(-50%);
							top: 50%;

							left: 0;
							right: 0;
							margin: 0 auto;
							width: 80%;

							font-size: 22px;
							font-weight: bold;
							text-align: center;
							color: #ffffff;
							text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
						}
					}
				}

				.title {
					margin-bottom: 10px;
					span {
						font-size: 22px;
						font-weight: bold;
						color: $black;
					}
				}

				.desc {
					span {
						font-size: 16px;
						font-weight: 300;
						color: #1e1e1e;
						font-family: $sfont;
					}
				}
			}
		}
	}
}