body {
	font-family: $font;
}

.banner_bg {
	background-image: linear-gradient(294deg, #d0021b, #000000);
}

.banner {
	margin-bottom: 0 !important;

	@include bp(max-width, $medium) {
		margin-bottom: 0 !important;
	}

	.slick-dots {
		@include bp(max-width, $medium) {
			display: none;
		}
	}

	.container {
		position: relative;
		height: 620px;

		@include bp(max-width, $small) {
			height: 450px;
		}
	}

	.item {
		height: 620px;

		@include bp(max-width, $medium) {
			height: 250px;
		}

		h1 {
			font-size: 100px;
			color: white;
			position: absolute;
			left: 0;
			z-index: 2;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			top: 50%;
			width: 80%;
			margin: 0;
			font-weight: 900;
			color: white;
			text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);


			@include bp(max-width, $xlarge) {
    			font-size: 80px;
			}

			@include bp(max-width, $large) {
    			font-size: 60px;
			}

			@include bp(max-width, $medium) {
    			text-align: center;
    			width: 100%;
    			padding: 0 5%;
			}

			@include bp(max-width, $small) {
    			font-size: 40px;
			}

		}

		.image {
			height: 450px;
			background-position:center;
			background-size: contain;
			background-repeat: no-repeat;
			position: relative;
		    width: 70%;

			transform: translateY(-50%); 
			-webkit-transform: translateY(-50%); 
			top: 50%; 

		    margin-left: auto;

		    @include bp(max-width, $medium) {
		    	height: 250px;
    			width: 100%;
    			background-size: cover;
			}
		}
	}
}

.slick-next {
	&::before {
		font-family: 'FontAwesome';
		content: '\f054';
		font-size: 40px;
		color: black;
	}
}

.slick-prev {
	&::before {
		font-family: 'FontAwesome';
		content: '\f053';
		font-size: 40px;
		color: black;
		position: absolute;
		left: -30px;
	}
}

.slick-dots {
	bottom: 30px;
	width: 70%;
	right: 0;

	@include bp(max-width, $medium) {
		width: 100%;
	}

	li {
		width: 67px;
		height: 4px;
	    margin: 0 20px;
		button {
			width: 67px;
			height: 4px;
			&:before {
				content: " ";
				background: white;
				width: 67px;
				height: 4px;
				opacity: 0.3;
			}
		}
	}

	.slick-active {
		button {
			&:before {
				opacity: 0.8;
			}
		}
	}
}

.title, .large-title {
	margin-top: 0 !important;
}