// core
@import "variables";
@import "mixins/transitions";
@import "mixins/breakpoints";
@import "mixins/fonts";
@import "fonts";

// bower
@import '../bower_components/bootstrap-sass/assets/stylesheets/bootstrap';
@import '../bower_components/slick-carousel/slick/slick.scss';
@import '../bower_components/slick-carousel/slick/slick-theme.scss';
@import "../bower_components/components-font-awesome/scss/font-awesome";
@import "../bower_components/slicknav/scss/core";
@import "../bower_components/slicknav/scss/slicknav";
@import "../bower_components/slicknav/scss/theme";



// custom
@import "layouts/header";
@import "layouts/footer";
@import "layouts/common";
@import "layouts/home";
@import "layouts/services";
@import "layouts/services-single";
@import "layouts/contact";
// @import "layouts/case-studies";